<template>
  <TicketsHeader
    v-model:search="search"
    :searchStatus="this.searchStatus"
    @pushStatusSearch="this.pushSearchStatus($event)"
    title="Incoming Emails"
    subtitle="Replies to closed tickets"
  />
  <div class="flex flex-col">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <SuccessBanner
        :key="key"
        :msg="successMsg"
        :active="activeBanner"
        @closeBanner="activeBanner = false"
        :isSuccess="isSuccess"
      />
      <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div class="overflow-hidden">
          <IncomingEmailsTable
            v-if="backendPagination"
            :tablePaginatedData="this.filteredIncomingEmails()"
            :backendPagination="backendPagination"
            :to="to"
            :from="from"
            :total="total"
            :lastPage="lastPage"
            @firstPage="firstPage"
            @previousPage="previousPage"
            @nextPage="nextPage"
            @lastPage="goLastPage"
            @sort="sort($event)"
            :outTickets="false"
            @activeBanner="
              (activeBanner = true),
                getIncomingEmailsReplyToClosedTicket(),
                (key = !key)
            "
            @successMsg="successMsg = $event"
            @success="isSuccess = $event"
          />
        </div>
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";

import TicketsHeader from "../../../components/tickets/TicketsHeader.vue";
import IncomingEmailsTable from "../../../components/tickets/incomingEmails/IncomingEmailsTable.vue";
import SuccessBanner from "../../../components/SuccessBanner.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "ReplyToClosedTicketIncomingEmails",
  props: ["page"],
  components: {
    TicketsHeader,
    Loading,
    IncomingEmailsTable,
    SuccessBanner,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      tickets: [],
      incomingEmailsReplyToClosedTicket: [],
      currentSort: "id",
      currentSortDir: "asc",
      search: "",
      searchDate: "",
      searchStatus: [],
      searchPriority: [],
      searchDateUpdate: "",
      status: [],
      to: "",
      from: "",
      total: "",
      lastPage: "",
      backendPagination: false,
      currentPage: 1,
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
    };
  },
  methods: {
    async getIncomingEmailsReplyToClosedTicket() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/incomingMails`,
          {
            params: { filter: "replyToClosedTicket" },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.incomingEmailsReplyToClosedTicket = res.data.data;
        this.backendPagination = true;
        this.to = res.data.to;
        this.from = res.data.from;
        this.total = res.data.total;
        this.lastPage = res.data.last_page;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    firstPage() {
      let firstPage = 1;
      this.currentPage = firstPage;

      this.$router.push(
        `/tickets/incoming-emails/closed-tickets-replied/${firstPage}`
      );
    },
    nextPage() {
      let nextPage = null;
      if (parseInt(this.page) < parseInt(this.lastPage)) {
        nextPage = parseInt(this.page) + 1;
      } else {
        nextPage = parseInt(this.page);
      }
      this.currentPage = nextPage;
      this.$router.push(
        `/tickets/incoming-emails/closed-tickets-replied/${nextPage}`
      );

      this.$forceUpdate();
    },
    previousPage() {
      let previousPage = null;
      if (this.page > 1) {
        previousPage = parseInt(this.page) - 1;
      } else {
        previousPage = parseInt(this.page);
      }
      this.currentPage = previousPage;
      this.$router.push(
        `/tickets/incoming-emails/closed-tickets-replied/${previousPage}`
      );
      this.$forceUpdate();
    },
    goLastPage() {
      this.currentPage = this.lastPage;
      this.$router.push(
        `/tickets/incoming-emails/closed-tickets-replied/${this.lastPage}`
      );
      this.$forceUpdate();
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    checkStatus(id) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].id == id) {
            return this.status[index].name;
          }
        }
      }
    },
    checkPriority(id) {
      for (let index = 0; index < this.priorities.length; index++) {
        if (this.priorities[index].id == id) {
          return this.priorities[index].name;
        }
      }
    },
    numberIsEqual(numb) {
      return String(numb).includes(String(this.search));
    },

    filteredIncomingEmails() {
      return this.incomingEmailsReplyToClosedTicket.filter((list) => {
        return (
          String(list.subject)
            .toLowerCase()
            .includes(String(this.search).toLowerCase()) ||
          String(list.created_at).includes(String(this.search))
        );
      });
    },
  },
  mounted() {
    this.getIncomingEmailsReplyToClosedTicket();
  },
  watch: {
    searchStatus: function(val) {
      this.filteredIncomingEmails();
    },
  },
};
</script>

<style></style>
